import { useGrpcClientWithLogger } from "@/components/modules/rec-system/services/UserGroups/middleware";
import { MessageGrpClsServiceClient } from "proto/nigma-message-proto/polls/message_grp_cls.client";
import {
  ListMessageGrpClsInRequest,
  ListMessageGrpClsOutRequest,
} from "proto/nigma-message-proto/polls/message_grp_cls";

export const useMessageGrpClsApi = () => {
  const client = useGrpcClientWithLogger().create(MessageGrpClsServiceClient, {
    baseUrl: window.VUE_APP_GATEWAY_URL,
  });

  const metadata = () => {
    return {
      Authorization: localStorage.getItem("access-key") || "",
    };
  };

  return {
    listMessageGrpClsInRequest: (
      request: ListMessageGrpClsInRequest,
      abortController?: AbortController
    ) => {
      return client
        .listMessageGrpClsIn(request, {
          abort: abortController?.signal,
        })
        .response.catch(console.error);
    },
    listMessageGrpClsOutRequest: (
      request: ListMessageGrpClsOutRequest,
      abortController?: AbortController
    ) => {
      return client
        .listMessageGrpClsOut(request, {
          abort: abortController?.signal,
        })
        .response.catch(console.error);
    },
  };
};
