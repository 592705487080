// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_grp.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров mg.
 *
 * @generated from protobuf message proto.nigmamsg.MessageGrpModel
 */
export interface MessageGrpModel {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_parent_id = 2;
     */
    messageGrpParentId: number;
    /**
     * @generated from protobuf field: string message_grp_text = 3;
     */
    messageGrpText: string;
    /**
     * @generated from protobuf field: int64 message_grp_count = 4;
     */
    messageGrpCount: number;
    /**
     * @generated from protobuf field: bool message_grp_processed = 5;
     */
    messageGrpProcessed: boolean;
    /**
     * @generated from protobuf field: int64 message_grp_sum = 6;
     */
    messageGrpSum: number; // Префикс s., в фильтрах не использовать
    /**
     * @generated from protobuf field: double similarity = 7;
     */
    similarity: number; // Только сортировка, без префикса
    /**
     * @generated from protobuf field: bool has_childs = 8;
     */
    hasChilds: boolean; // Только сортировка, без префикса
}
/**
 * Входящий запрос для получения корневых элементов (message_grp_id == message_grp_parent_id)
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpRequest
 */
export interface ListMessageGrpRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: bool is_worked = 4;
     */
    isWorked: boolean;
    /**
     * @generated from protobuf field: repeated int64 message_type_ids = 5;
     */
    messageTypeIds: number[];
}
/**
 * Ответ сервера для получения корневых элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpResponse
 */
export interface ListMessageGrpResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageGrpModel items = 1;
     */
    items: MessageGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageGrpRequest params = 2;
     */
    params?: ListMessageGrpRequest;
}
/**
 * Входящий запрос для получения корневых элементов (message_grp_id === message_grp_parent_id)
 * с поиском страницы для заданного сообщения
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpWithFindMessageRequest
 */
export interface ListMessageGrpWithFindMessageRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 message_grp_id = 4;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: repeated int64 message_type_ids = 5;
     */
    messageTypeIds: number[];
}
/**
 * Ответ сервера для получения корневых элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpWithFindMessageResponse
 */
export interface ListMessageGrpWithFindMessageResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageGrpModel items = 1;
     */
    items: MessageGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageGrpWithFindMessageRequest params = 2;
     */
    params?: ListMessageGrpWithFindMessageRequest;
}
/**
 * Входящий запрос для получения дочерних(только листовых) элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpChildsRequest
 */
export interface ListMessageGrpChildsRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 message_grp_id = 4;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: repeated int64 message_type_ids = 5;
     */
    messageTypeIds: number[];
}
/**
 * Ответ сервера для получения дочерних элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpChildsResponse
 */
export interface ListMessageGrpChildsResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageGrpModel items = 1;
     */
    items: MessageGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageGrpChildsRequest params = 2;
     */
    params?: ListMessageGrpChildsRequest;
}
/**
 * Входящий запрос для обновления текста элемента (вызывается перед разбиением)
 *
 * @generated from protobuf message proto.nigmamsg.UpdateTextMessageGrpRequest
 */
export interface UpdateTextMessageGrpRequest {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: string message_grp_text = 2;
     */
    messageGrpText: string;
}
/**
 * Ответ сервера для обновления текста элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateTextMessageGrpResponse
 */
export interface UpdateTextMessageGrpResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для сведения сообщений к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceMessageGrpRequest
 */
export interface ReduceMessageGrpRequest {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: repeated int64 reduced_ids = 2;
     */
    reducedIds: number[];
}
/**
 * Ответ сервера после сведения сообщений к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceMessageGrpResponse
 */
export interface ReduceMessageGrpResponse {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
}
/**
 * Входящий запрос для разбиения сообщений по разделителю (split)
 *
 * @generated from protobuf message proto.nigmamsg.SplitMessageGrpRequest
 */
export interface SplitMessageGrpRequest {
    /**
     * @generated from protobuf field: string split_string = 1;
     */
    splitString: string;
    /**
     * @generated from protobuf field: repeated int64 splitted_ids = 2;
     */
    splittedIds: number[];
}
/**
 * Ответ сервера после разбиения сообщений по разделителю (split)
 *
 * @generated from protobuf message proto.nigmamsg.SplitMessageGrpResponse
 */
export interface SplitMessageGrpResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для разобщения сообщений (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateMessageGrpRequest
 */
export interface SeparateMessageGrpRequest {
    /**
     * @generated from protobuf field: repeated int64 separated_ids = 1;
     */
    separatedIds: number[];
}
/**
 * Ответ сервера после разобщения сообщений (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateMessageGrpResponse
 */
export interface SeparateMessageGrpResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageGrpModel$Type extends MessageType<MessageGrpModel> {
    constructor() {
        super("proto.nigmamsg.MessageGrpModel", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "message_grp_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "message_grp_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "message_grp_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "message_grp_sum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "similarity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "has_childs", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MessageGrpModel>): MessageGrpModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageGrpParentId = 0;
        message.messageGrpText = "";
        message.messageGrpCount = 0;
        message.messageGrpProcessed = false;
        message.messageGrpSum = 0;
        message.similarity = 0;
        message.hasChilds = false;
        if (value !== undefined)
            reflectionMergePartial<MessageGrpModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageGrpModel): MessageGrpModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_parent_id */ 2:
                    message.messageGrpParentId = reader.int64().toNumber();
                    break;
                case /* string message_grp_text */ 3:
                    message.messageGrpText = reader.string();
                    break;
                case /* int64 message_grp_count */ 4:
                    message.messageGrpCount = reader.int64().toNumber();
                    break;
                case /* bool message_grp_processed */ 5:
                    message.messageGrpProcessed = reader.bool();
                    break;
                case /* int64 message_grp_sum */ 6:
                    message.messageGrpSum = reader.int64().toNumber();
                    break;
                case /* double similarity */ 7:
                    message.similarity = reader.double();
                    break;
                case /* bool has_childs */ 8:
                    message.hasChilds = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageGrpModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* int64 message_grp_parent_id = 2; */
        if (message.messageGrpParentId !== 0)
            writer.tag(2, WireType.Varint).int64(message.messageGrpParentId);
        /* string message_grp_text = 3; */
        if (message.messageGrpText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.messageGrpText);
        /* int64 message_grp_count = 4; */
        if (message.messageGrpCount !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpCount);
        /* bool message_grp_processed = 5; */
        if (message.messageGrpProcessed !== false)
            writer.tag(5, WireType.Varint).bool(message.messageGrpProcessed);
        /* int64 message_grp_sum = 6; */
        if (message.messageGrpSum !== 0)
            writer.tag(6, WireType.Varint).int64(message.messageGrpSum);
        /* double similarity = 7; */
        if (message.similarity !== 0)
            writer.tag(7, WireType.Bit64).double(message.similarity);
        /* bool has_childs = 8; */
        if (message.hasChilds !== false)
            writer.tag(8, WireType.Varint).bool(message.hasChilds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageGrpModel
 */
export const MessageGrpModel = new MessageGrpModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpRequest$Type extends MessageType<ListMessageGrpRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "is_worked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "message_type_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpRequest>): ListMessageGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isWorked = false;
        message.messageTypeIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpRequest): ListMessageGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* bool is_worked */ 4:
                    message.isWorked = reader.bool();
                    break;
                case /* repeated int64 message_type_ids */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageTypeIds.push(reader.int64().toNumber());
                    else
                        message.messageTypeIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_worked = 4; */
        if (message.isWorked !== false)
            writer.tag(4, WireType.Varint).bool(message.isWorked);
        /* repeated int64 message_type_ids = 5; */
        if (message.messageTypeIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageTypeIds.length; i++)
                writer.int64(message.messageTypeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpRequest
 */
export const ListMessageGrpRequest = new ListMessageGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpResponse$Type extends MessageType<ListMessageGrpResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageGrpRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpResponse>): ListMessageGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpResponse): ListMessageGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageGrpModel items */ 1:
                    message.items.push(MessageGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageGrpRequest params */ 2:
                    message.params = ListMessageGrpRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageGrpRequest params = 2; */
        if (message.params)
            ListMessageGrpRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpResponse
 */
export const ListMessageGrpResponse = new ListMessageGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpWithFindMessageRequest$Type extends MessageType<ListMessageGrpWithFindMessageRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpWithFindMessageRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "message_type_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpWithFindMessageRequest>): ListMessageGrpWithFindMessageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageTypeIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpWithFindMessageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpWithFindMessageRequest): ListMessageGrpWithFindMessageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 message_grp_id */ 4:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* repeated int64 message_type_ids */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageTypeIds.push(reader.int64().toNumber());
                    else
                        message.messageTypeIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpWithFindMessageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 message_grp_id = 4; */
        if (message.messageGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpId);
        /* repeated int64 message_type_ids = 5; */
        if (message.messageTypeIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageTypeIds.length; i++)
                writer.int64(message.messageTypeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpWithFindMessageRequest
 */
export const ListMessageGrpWithFindMessageRequest = new ListMessageGrpWithFindMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpWithFindMessageResponse$Type extends MessageType<ListMessageGrpWithFindMessageResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpWithFindMessageResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageGrpWithFindMessageRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpWithFindMessageResponse>): ListMessageGrpWithFindMessageResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpWithFindMessageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpWithFindMessageResponse): ListMessageGrpWithFindMessageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageGrpModel items */ 1:
                    message.items.push(MessageGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageGrpWithFindMessageRequest params */ 2:
                    message.params = ListMessageGrpWithFindMessageRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpWithFindMessageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageGrpWithFindMessageRequest params = 2; */
        if (message.params)
            ListMessageGrpWithFindMessageRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpWithFindMessageResponse
 */
export const ListMessageGrpWithFindMessageResponse = new ListMessageGrpWithFindMessageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpChildsRequest$Type extends MessageType<ListMessageGrpChildsRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpChildsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "message_type_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpChildsRequest>): ListMessageGrpChildsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageTypeIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpChildsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpChildsRequest): ListMessageGrpChildsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 message_grp_id */ 4:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* repeated int64 message_type_ids */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageTypeIds.push(reader.int64().toNumber());
                    else
                        message.messageTypeIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpChildsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 message_grp_id = 4; */
        if (message.messageGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpId);
        /* repeated int64 message_type_ids = 5; */
        if (message.messageTypeIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageTypeIds.length; i++)
                writer.int64(message.messageTypeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpChildsRequest
 */
export const ListMessageGrpChildsRequest = new ListMessageGrpChildsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpChildsResponse$Type extends MessageType<ListMessageGrpChildsResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpChildsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageGrpChildsRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpChildsResponse>): ListMessageGrpChildsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpChildsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpChildsResponse): ListMessageGrpChildsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageGrpModel items */ 1:
                    message.items.push(MessageGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageGrpChildsRequest params */ 2:
                    message.params = ListMessageGrpChildsRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpChildsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageGrpChildsRequest params = 2; */
        if (message.params)
            ListMessageGrpChildsRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpChildsResponse
 */
export const ListMessageGrpChildsResponse = new ListMessageGrpChildsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTextMessageGrpRequest$Type extends MessageType<UpdateTextMessageGrpRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateTextMessageGrpRequest", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateTextMessageGrpRequest>): UpdateTextMessageGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageGrpText = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateTextMessageGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTextMessageGrpRequest): UpdateTextMessageGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* string message_grp_text */ 2:
                    message.messageGrpText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTextMessageGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* string message_grp_text = 2; */
        if (message.messageGrpText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.messageGrpText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateTextMessageGrpRequest
 */
export const UpdateTextMessageGrpRequest = new UpdateTextMessageGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTextMessageGrpResponse$Type extends MessageType<UpdateTextMessageGrpResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateTextMessageGrpResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateTextMessageGrpResponse>): UpdateTextMessageGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateTextMessageGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTextMessageGrpResponse): UpdateTextMessageGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTextMessageGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateTextMessageGrpResponse
 */
export const UpdateTextMessageGrpResponse = new UpdateTextMessageGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceMessageGrpRequest$Type extends MessageType<ReduceMessageGrpRequest> {
    constructor() {
        super("proto.nigmamsg.ReduceMessageGrpRequest", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reduced_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ReduceMessageGrpRequest>): ReduceMessageGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.reducedIds = [];
        if (value !== undefined)
            reflectionMergePartial<ReduceMessageGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceMessageGrpRequest): ReduceMessageGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* repeated int64 reduced_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.reducedIds.push(reader.int64().toNumber());
                    else
                        message.reducedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceMessageGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* repeated int64 reduced_ids = 2; */
        if (message.reducedIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.reducedIds.length; i++)
                writer.int64(message.reducedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceMessageGrpRequest
 */
export const ReduceMessageGrpRequest = new ReduceMessageGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceMessageGrpResponse$Type extends MessageType<ReduceMessageGrpResponse> {
    constructor() {
        super("proto.nigmamsg.ReduceMessageGrpResponse", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ReduceMessageGrpResponse>): ReduceMessageGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        if (value !== undefined)
            reflectionMergePartial<ReduceMessageGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceMessageGrpResponse): ReduceMessageGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceMessageGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceMessageGrpResponse
 */
export const ReduceMessageGrpResponse = new ReduceMessageGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SplitMessageGrpRequest$Type extends MessageType<SplitMessageGrpRequest> {
    constructor() {
        super("proto.nigmamsg.SplitMessageGrpRequest", [
            { no: 1, name: "split_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "splitted_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SplitMessageGrpRequest>): SplitMessageGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.splitString = "";
        message.splittedIds = [];
        if (value !== undefined)
            reflectionMergePartial<SplitMessageGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SplitMessageGrpRequest): SplitMessageGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string split_string */ 1:
                    message.splitString = reader.string();
                    break;
                case /* repeated int64 splitted_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.splittedIds.push(reader.int64().toNumber());
                    else
                        message.splittedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SplitMessageGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string split_string = 1; */
        if (message.splitString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.splitString);
        /* repeated int64 splitted_ids = 2; */
        if (message.splittedIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.splittedIds.length; i++)
                writer.int64(message.splittedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SplitMessageGrpRequest
 */
export const SplitMessageGrpRequest = new SplitMessageGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SplitMessageGrpResponse$Type extends MessageType<SplitMessageGrpResponse> {
    constructor() {
        super("proto.nigmamsg.SplitMessageGrpResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SplitMessageGrpResponse>): SplitMessageGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SplitMessageGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SplitMessageGrpResponse): SplitMessageGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SplitMessageGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SplitMessageGrpResponse
 */
export const SplitMessageGrpResponse = new SplitMessageGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateMessageGrpRequest$Type extends MessageType<SeparateMessageGrpRequest> {
    constructor() {
        super("proto.nigmamsg.SeparateMessageGrpRequest", [
            { no: 1, name: "separated_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateMessageGrpRequest>): SeparateMessageGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.separatedIds = [];
        if (value !== undefined)
            reflectionMergePartial<SeparateMessageGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateMessageGrpRequest): SeparateMessageGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 separated_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.separatedIds.push(reader.int64().toNumber());
                    else
                        message.separatedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateMessageGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 separated_ids = 1; */
        if (message.separatedIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.separatedIds.length; i++)
                writer.int64(message.separatedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateMessageGrpRequest
 */
export const SeparateMessageGrpRequest = new SeparateMessageGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateMessageGrpResponse$Type extends MessageType<SeparateMessageGrpResponse> {
    constructor() {
        super("proto.nigmamsg.SeparateMessageGrpResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateMessageGrpResponse>): SeparateMessageGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SeparateMessageGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateMessageGrpResponse): SeparateMessageGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateMessageGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateMessageGrpResponse
 */
export const SeparateMessageGrpResponse = new SeparateMessageGrpResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.MessageGrpService
 */
export const MessageGrpService = new ServiceType("proto.nigmamsg.MessageGrpService", [
    { name: "ListMessageGrp", options: {}, I: ListMessageGrpRequest, O: ListMessageGrpResponse },
    { name: "ListMessageGrpWithFindMessage", options: {}, I: ListMessageGrpWithFindMessageRequest, O: ListMessageGrpWithFindMessageResponse },
    { name: "ListMessageGrpChilds", options: {}, I: ListMessageGrpChildsRequest, O: ListMessageGrpChildsResponse },
    { name: "UpdateTextMessageGrp", options: {}, I: UpdateTextMessageGrpRequest, O: UpdateTextMessageGrpResponse },
    { name: "ReduceMessageGrp", options: {}, I: ReduceMessageGrpRequest, O: ReduceMessageGrpResponse },
    { name: "SplitMessageGrp", options: {}, I: SplitMessageGrpRequest, O: SplitMessageGrpResponse },
    { name: "SeparateMessageGrp", options: {}, I: SeparateMessageGrpRequest, O: SeparateMessageGrpResponse }
]);
