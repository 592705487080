import script from "./MessageChilds.vue?vue&type=script&setup=true&lang=ts"
export * from "./MessageChilds.vue?vue&type=script&setup=true&lang=ts"

import "./MessageChilds.vue?vue&type=style&index=0&id=574a4fb1&lang=scss"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QCheckbox,QTd,QIcon,QTooltip,QList,QItem,QItemSection,QBtn,QInnerLoading});
