// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_grp_cls.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных для списка "Привязанные"
 * Префикс для фильтров mg.
 *
 * @generated from protobuf message proto.nigmamsg.MessageGrpClsInModel
 */
export interface MessageGrpClsInModel {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: string message_grp_text = 2;
     */
    messageGrpText: string;
    /**
     * @generated from protobuf field: int64 message_grp_count = 3;
     */
    messageGrpCount: number;
    /**
     * @generated from protobuf field: int64 message_grp_sum = 4;
     */
    messageGrpSum: number; // Префикс s., в фильтрах не использовать
    /**
     * @generated from protobuf field: int64 class_id = 5;
     */
    classId: number; // Префикс для фильтров mc.
}
/**
 * Модель данных для списка "Непривязанные"
 * Префикс для фильтров mg.
 * Вся сортировка без префикса !!!
 *
 * @generated from protobuf message proto.nigmamsg.MessageGrpClsOutModel
 */
export interface MessageGrpClsOutModel {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: string message_grp_text = 2;
     */
    messageGrpText: string;
    /**
     * @generated from protobuf field: int64 message_grp_count = 3;
     */
    messageGrpCount: number;
    /**
     * @generated from protobuf field: int64 message_grp_sum = 4;
     */
    messageGrpSum: number; // Префикс s., в фильтрах не использовать
    /**
     * @generated from protobuf field: bool has_classes = 5;
     */
    hasClasses: boolean; // Только сортировка, без префикса
}
/**
 * Входящий запрос для получения списка "Привязанные"
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpClsInRequest
 */
export interface ListMessageGrpClsInRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 class_id = 4;
     */
    classId: number;
    /**
     * @generated from protobuf field: bool view_child = 5;
     */
    viewChild: boolean;
    /**
     * @generated from protobuf field: repeated int64 message_type_ids = 6;
     */
    messageTypeIds: number[];
}
/**
 * Ответ сервера для получения списка "Привязанные"
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpClsInResponse
 */
export interface ListMessageGrpClsInResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageGrpClsInModel items = 1;
     */
    items: MessageGrpClsInModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageGrpClsInRequest params = 2;
     */
    params?: ListMessageGrpClsInRequest;
}
/**
 * Входящий запрос для получения списка "Непривязанные"
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpClsOutRequest
 */
export interface ListMessageGrpClsOutRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 class_id = 4;
     */
    classId: number;
    /**
     * @generated from protobuf field: bool view_child = 5;
     */
    viewChild: boolean;
    /**
     * @generated from protobuf field: bool not_classifier_only = 6;
     */
    notClassifierOnly: boolean;
    /**
     * @generated from protobuf field: repeated int64 message_type_ids = 7;
     */
    messageTypeIds: number[];
}
/**
 * Ответ сервера для получения списка "Непривязанные"
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageGrpClsOutResponse
 */
export interface ListMessageGrpClsOutResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageGrpClsOutModel items = 1;
     */
    items: MessageGrpClsOutModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageGrpClsOutRequest params = 2;
     */
    params?: ListMessageGrpClsOutRequest;
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageGrpClsInModel$Type extends MessageType<MessageGrpClsInModel> {
    constructor() {
        super("proto.nigmamsg.MessageGrpClsInModel", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message_grp_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "message_grp_sum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MessageGrpClsInModel>): MessageGrpClsInModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageGrpText = "";
        message.messageGrpCount = 0;
        message.messageGrpSum = 0;
        message.classId = 0;
        if (value !== undefined)
            reflectionMergePartial<MessageGrpClsInModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageGrpClsInModel): MessageGrpClsInModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* string message_grp_text */ 2:
                    message.messageGrpText = reader.string();
                    break;
                case /* int64 message_grp_count */ 3:
                    message.messageGrpCount = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_sum */ 4:
                    message.messageGrpSum = reader.int64().toNumber();
                    break;
                case /* int64 class_id */ 5:
                    message.classId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageGrpClsInModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* string message_grp_text = 2; */
        if (message.messageGrpText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.messageGrpText);
        /* int64 message_grp_count = 3; */
        if (message.messageGrpCount !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageGrpCount);
        /* int64 message_grp_sum = 4; */
        if (message.messageGrpSum !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpSum);
        /* int64 class_id = 5; */
        if (message.classId !== 0)
            writer.tag(5, WireType.Varint).int64(message.classId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageGrpClsInModel
 */
export const MessageGrpClsInModel = new MessageGrpClsInModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageGrpClsOutModel$Type extends MessageType<MessageGrpClsOutModel> {
    constructor() {
        super("proto.nigmamsg.MessageGrpClsOutModel", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message_grp_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "message_grp_sum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "has_classes", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MessageGrpClsOutModel>): MessageGrpClsOutModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageGrpText = "";
        message.messageGrpCount = 0;
        message.messageGrpSum = 0;
        message.hasClasses = false;
        if (value !== undefined)
            reflectionMergePartial<MessageGrpClsOutModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageGrpClsOutModel): MessageGrpClsOutModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* string message_grp_text */ 2:
                    message.messageGrpText = reader.string();
                    break;
                case /* int64 message_grp_count */ 3:
                    message.messageGrpCount = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_sum */ 4:
                    message.messageGrpSum = reader.int64().toNumber();
                    break;
                case /* bool has_classes */ 5:
                    message.hasClasses = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageGrpClsOutModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* string message_grp_text = 2; */
        if (message.messageGrpText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.messageGrpText);
        /* int64 message_grp_count = 3; */
        if (message.messageGrpCount !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageGrpCount);
        /* int64 message_grp_sum = 4; */
        if (message.messageGrpSum !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpSum);
        /* bool has_classes = 5; */
        if (message.hasClasses !== false)
            writer.tag(5, WireType.Varint).bool(message.hasClasses);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageGrpClsOutModel
 */
export const MessageGrpClsOutModel = new MessageGrpClsOutModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpClsInRequest$Type extends MessageType<ListMessageGrpClsInRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpClsInRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "view_child", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "message_type_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpClsInRequest>): ListMessageGrpClsInRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.viewChild = false;
        message.messageTypeIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpClsInRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpClsInRequest): ListMessageGrpClsInRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 class_id */ 4:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* bool view_child */ 5:
                    message.viewChild = reader.bool();
                    break;
                case /* repeated int64 message_type_ids */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageTypeIds.push(reader.int64().toNumber());
                    else
                        message.messageTypeIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpClsInRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 class_id = 4; */
        if (message.classId !== 0)
            writer.tag(4, WireType.Varint).int64(message.classId);
        /* bool view_child = 5; */
        if (message.viewChild !== false)
            writer.tag(5, WireType.Varint).bool(message.viewChild);
        /* repeated int64 message_type_ids = 6; */
        if (message.messageTypeIds.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageTypeIds.length; i++)
                writer.int64(message.messageTypeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpClsInRequest
 */
export const ListMessageGrpClsInRequest = new ListMessageGrpClsInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpClsInResponse$Type extends MessageType<ListMessageGrpClsInResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpClsInResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGrpClsInModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageGrpClsInRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpClsInResponse>): ListMessageGrpClsInResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpClsInResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpClsInResponse): ListMessageGrpClsInResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageGrpClsInModel items */ 1:
                    message.items.push(MessageGrpClsInModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageGrpClsInRequest params */ 2:
                    message.params = ListMessageGrpClsInRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpClsInResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageGrpClsInModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageGrpClsInModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageGrpClsInRequest params = 2; */
        if (message.params)
            ListMessageGrpClsInRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpClsInResponse
 */
export const ListMessageGrpClsInResponse = new ListMessageGrpClsInResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpClsOutRequest$Type extends MessageType<ListMessageGrpClsOutRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpClsOutRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "view_child", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "not_classifier_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "message_type_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpClsOutRequest>): ListMessageGrpClsOutRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.viewChild = false;
        message.notClassifierOnly = false;
        message.messageTypeIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpClsOutRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpClsOutRequest): ListMessageGrpClsOutRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 class_id */ 4:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* bool view_child */ 5:
                    message.viewChild = reader.bool();
                    break;
                case /* bool not_classifier_only */ 6:
                    message.notClassifierOnly = reader.bool();
                    break;
                case /* repeated int64 message_type_ids */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageTypeIds.push(reader.int64().toNumber());
                    else
                        message.messageTypeIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpClsOutRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 class_id = 4; */
        if (message.classId !== 0)
            writer.tag(4, WireType.Varint).int64(message.classId);
        /* bool view_child = 5; */
        if (message.viewChild !== false)
            writer.tag(5, WireType.Varint).bool(message.viewChild);
        /* bool not_classifier_only = 6; */
        if (message.notClassifierOnly !== false)
            writer.tag(6, WireType.Varint).bool(message.notClassifierOnly);
        /* repeated int64 message_type_ids = 7; */
        if (message.messageTypeIds.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageTypeIds.length; i++)
                writer.int64(message.messageTypeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpClsOutRequest
 */
export const ListMessageGrpClsOutRequest = new ListMessageGrpClsOutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageGrpClsOutResponse$Type extends MessageType<ListMessageGrpClsOutResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageGrpClsOutResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageGrpClsOutModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageGrpClsOutRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageGrpClsOutResponse>): ListMessageGrpClsOutResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageGrpClsOutResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageGrpClsOutResponse): ListMessageGrpClsOutResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageGrpClsOutModel items */ 1:
                    message.items.push(MessageGrpClsOutModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageGrpClsOutRequest params */ 2:
                    message.params = ListMessageGrpClsOutRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageGrpClsOutResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageGrpClsOutModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageGrpClsOutModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageGrpClsOutRequest params = 2; */
        if (message.params)
            ListMessageGrpClsOutRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageGrpClsOutResponse
 */
export const ListMessageGrpClsOutResponse = new ListMessageGrpClsOutResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.MessageGrpClsService
 */
export const MessageGrpClsService = new ServiceType("proto.nigmamsg.MessageGrpClsService", [
    { name: "ListMessageGrpClsIn", options: {}, I: ListMessageGrpClsInRequest, O: ListMessageGrpClsInResponse },
    { name: "ListMessageGrpClsOut", options: {}, I: ListMessageGrpClsOutRequest, O: ListMessageGrpClsOutResponse }
]);
