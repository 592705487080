import script from "./MultipleClassificationMessages.vue?vue&type=script&setup=true&lang=ts"
export * from "./MultipleClassificationMessages.vue?vue&type=script&setup=true&lang=ts"

import "./MultipleClassificationMessages.vue?vue&type=style&index=0&id=04064ed4&lang=sass"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QChip,QTooltip,QBtn,QSeparator,QCheckbox,QItemLabel,QInput,QTable,QTd,QTabPanels,QTabPanel,QMenu,QList,QItem,QSelect,QInnerLoading,QCardSection});
