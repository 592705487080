import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "table__check-icon row items-center" }
const _hoisted_4 = { class: "row items-center q-gutter-sm" }
const _hoisted_5 = { style: {"font-size":"14px"} }
const _hoisted_6 = { class: "q-ml-auto" }
const _hoisted_7 = { class: "col-12 text-center relative-position" }

import { onMounted, ref } from "vue";
import { MessageGrpModel } from "proto/nigma-message-proto/polls/message_grp";
import type { QTableColumn } from "quasar";
import {
  AgAnswerGrpIdsModel,
  AgAnswerGrpModel,
} from "proto/nigma-message-proto/polls/ag_answer_grp";
import { useMessageGrpApi } from "@/components/modules/polls/services/useMessageGrpApi";
import { useAgAnswerGrpApi } from "@/components/modules/polls/services/useAgAnswerGrpApi";
import { useAgQuestionApi } from "@/components/modules/polls/services/useAgQuestionApi";
import { AgQuestionModel } from "proto/nigma-message-proto/polls/ag_question";

interface MessageChildProps {
  tableKey: string;
  columns: QTableColumn[];
  type: string;
  agPollId?: number;
  agQuestionId?: number;
  currentItem?: MessageGrpModel | AgAnswerGrpModel;
  messageTypeIds: number[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageChilds',
  props: {
    tableKey: {},
    columns: {},
    type: {},
    agPollId: {},
    agQuestionId: {},
    currentItem: {},
    messageTypeIds: {}
  },
  emits: ["showSource", "afterSeparate", "checkChild"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { listMessageGrpChildsRequest, separateMessageGrpRequest } =
  useMessageGrpApi();
const { listAgAnswerGrpChildsRequest, separateAgAnswerGrpRequest } =
  useAgAnswerGrpApi();
const { listAgQuestionChildsRequest, separateAgQuestionRequest } =
  useAgQuestionApi();

const props = __props;
const emit = __emit;

const childTableData = ref<
  MessageGrpModel[] | AgAnswerGrpModel[] | AgQuestionModel[]
>([]);

const rowsPerPageResultOptions = [10, 25, 50, 100];
const selectedRows = ref<MessageGrpModel[] | AgAnswerGrpModel[]>([]);
const resultPagination = ref({
  sortBy:
    props.type === "question"
      ? "aq.answer_src_count"
      : `${props.type === "ag" ? "ag" : "mg"}.message_grp_count`,
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
});
const childLoader = ref(false);
const tableColors = [
  { name: "Глобальное обобщение", color: "#21ba45" },
  { name: "Обобщение во всех опросах", color: "#fbc02d" },
  { name: "Обобщение в конкретном опросе", color: "#00bcd4" },
  { name: "Обобщение в конкретном вопросе", color: "#c10015" },
];

const onRequestResult = (request: any) => {
  resultPagination.value = request.pagination;
  loadChildItems();
};

const onRowShowMessageSource = (row: any, isFull: boolean) => {
  let rowNew = JSON.parse(JSON.stringify(row));
  console.log("rowNew", rowNew);
  rowNew.sum = rowNew.count;
  emit("showSource", rowNew, isFull);
};

const loadChildItems = () => {
  childLoader.value = true;

  if (props.type === "ag") {
    listAgAnswerGrpChildsRequest({
      filter: {
        items: [],
      },
      sort: {
        name: resultPagination.value.sortBy,
        exp: resultPagination.value.descending ? "DESC" : "ASC",
      },
      pagination: {
        page: resultPagination.value.page,
        limit: resultPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      agPollId: props.agPollId || 0,
      agQuestionId: props.agQuestionId || 0,
      item: {
        agPollId: props.currentItem?.agPollId,
        agAnswerId: props.currentItem?.agAnswerId,
        agAnswerGrpId: props.currentItem?.agAnswerGrpId,
        messageGrpId: props.currentItem?.messageGrpId || 0,
      },
    })
      .then((res) => {
        resultPagination.value.rowsNumber = res?.params?.pagination?.cnt || 0;
        childTableData.value = res.items;
      })
      .finally(() => (childLoader.value = false));
  } else if (props.type === "all") {
    listMessageGrpChildsRequest({
      filter: {
        items: [],
      },
      sort: {
        name: resultPagination.value.sortBy,
        exp: resultPagination.value.descending ? "DESC" : "ASC",
      },
      pagination: {
        page: resultPagination.value.page,
        limit: resultPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      messageGrpId: props.currentItem?.messageGrpId || 0,
      messageTypeIds: props.messageTypeIds,
    })
      .then((res) => {
        resultPagination.value.rowsNumber = res?.params?.pagination?.cnt || 0;
        childTableData.value = res.items;
      })
      .finally(() => (childLoader.value = false));
  } else {
    listAgQuestionChildsRequest({
      filter: {
        items: [],
      },
      sort: {
        name: resultPagination.value.sortBy,
        exp: resultPagination.value.descending ? "DESC" : "ASC",
      },
      pagination: {
        page: resultPagination.value.page,
        limit: resultPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      agQuestionId: props.agQuestionId || 0,
    })
      .then((res) => {
        resultPagination.value.rowsNumber = res?.params?.pagination?.cnt || 0;
        childTableData.value = res.items;
      })
      .finally(() => (childLoader.value = false));
  }
};

const separateChild = () => {
  const reducedAgIds: number[] = [];
  const reducedAllIds: number[] = [];
  const currentItemIds: AgAnswerGrpIdsModel | undefined = props.currentItem
    ? {
        agPollId: props.currentItem.agPollId,
        agAnswerId: props.currentItem.agAnswerId,
        agAnswerGrpId: props.currentItem.agAnswerGrpId,
        messageGrpId: props.currentItem.messageGrpId,
      }
    : undefined;

  selectedRows.value.forEach((el) =>
    el.agAnswerGrpId !== 0
      ? reducedAgIds.push(el.agAnswerGrpId)
      : reducedAllIds.push(el.messageGrpId)
  );

  if (props.type === "ag") {
    if (reducedAgIds.length) {
      separateAgAnswerGrpRequest({
        separatedIds: reducedAgIds,
      })
        .then(() =>
          reducedAllIds.length
            ? separateMessageGrpRequest({ separatedIds: reducedAllIds })
            : null
        )
        .then(() => emit("afterSeparate", currentItemIds));
    } else {
      separateMessageGrpRequest({ separatedIds: reducedAllIds }).then(() =>
        emit("afterSeparate", currentItemIds)
      );
    }
  } else if (props.type === "all") {
    separateMessageGrpRequest({
      separatedIds: selectedRows.value.map((item) => item.messageGrpId),
    }).then(() => emit("afterSeparate"));
  } else if (props.type === "question") {
    separateAgQuestionRequest({
      separatedIds: selectedRows.value.map((item) => item.agQuestionId),
    }).then(() => emit("afterSeparate"));
  }
};

const checkSelectedChild = () => emit("checkChild", selectedRows.value);

const getGeneralizationColor = (row: AgAnswerGrpModel) => {
  if (row.agAnswerGrpId === 0) {
    return tableColors[0].color;
  } else {
    if (row.agAnswerId === 0 && row.agPollId === 0) return tableColors[1].color;
    if (row.agAnswerId === 0 && row.agPollId !== 0) return tableColors[2].color;
    if (row.agAnswerId !== 0 && row.agPollId !== 0) return tableColors[3].color;
  }
};

__expose({
  separateChild,
});

onMounted(() => loadChildItems());

return (_ctx: any,_cache: any) => {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    style: {"overflow":"auto","width":"100%"},
    class: "sticky-header-table-answer",
    dense: "",
    rows: childTableData.value,
    columns: _ctx.columns,
    "row-key": props.type === 'question' ? 'agQuestionId' : 'messageGrpId',
    "wrap-cells": "",
    selection: "multiple",
    pagination: resultPagination.value,
    "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((resultPagination).value = $event)),
    "rows-per-page-options": rowsPerPageResultOptions,
    onRequest: onRequestResult,
    key: _ctx.tableKey,
    "hide-header": "",
    selected: selectedRows.value,
    "onUpdate:selected": [
      _cache[1] || (_cache[1] = ($event: any) => ((selectedRows).value = $event)),
      checkSelectedChild
    ]
  }, {
    header: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_th, { style: {"width":"40px"} }),
          _createVNode(_component_q_th, {
            "auto-width": "",
            key: "messageGrpText",
            props: props,
            class: "text-left"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.cols[0].label), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_th, {
            key: "messageGrpCount",
            props: props,
            class: "text-center",
            style: {"width":"120px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.cols[1].label), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    body: _withCtx((props) => [
      _createVNode(_component_q_tr, {
        props: props,
        class: "row items-center no-wrap",
        style: {"width":"100%","height":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_checkbox, {
            modelValue: props.selected,
            "onUpdate:modelValue": ($event: any) => ((props.selected) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          (_openBlock(), _createBlock(_component_q_td, {
            key: _ctx.type === 'question' ? 'agQuestionStitle' : 'messageGrpText',
            props: props,
            style: _normalizeStyle([{"width":"100%","height":"100%"}, {
            background:
              _ctx.type === 'ag' ? getGeneralizationColor(props.row) : 'none',
          }])
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                style: _normalizeStyle({ color: _ctx.type === 'ag' ? 'white' : '' })
              }, _toDisplayString(_ctx.type === "question"
              ? props.row.agQuestionStitle
              : props.row.messageGrpText), 5)
            ]),
            _: 2
          }, 1032, ["props", "style"])),
          (_openBlock(), _createBlock(_component_q_td, {
            key: _ctx.type === 'question' ? 'answerSrcCount' : 'messageGrpCount',
            props: props,
            class: "text-center q-ml-auto",
            style: {"width":"120px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                style: {"cursor":"pointer"},
                title: "Исходные ответы",
                onClick: ($event: any) => (onRowShowMessageSource(props.row, false))
              }, _toDisplayString(_ctx.type === "question"
                ? props.row.answerSrcCount
                : props.row.messageGrpCount), 9, _hoisted_1)
            ]),
            _: 2
          }, 1032, ["props"]))
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    bottom: _withCtx((scope) => [
      (_ctx.type === 'ag')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[2] || (_cache[2] = _createTextVNode(" Обозначения цветов ")),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_icon, {
                name: "fas fa-info-circle",
                color: "teal",
                class: "table__warning-icon",
                size: "16px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_list, {
                        bordered: "",
                        dense: "",
                        separator: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tableColors, (item) => {
                            return _createVNode(_component_q_item, {
                              key: item.color
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_4, [
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{"width":"20px","height":"20px"}, { background: item.color }])
                                      }, null, 4),
                                      _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          }), 64))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString((
            scope.pagination.rowsPerPage * scope.pagination.page -
            scope.pagination.rowsPerPage +
            1
          ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
          scope.pagination.rowsNumber
            ? scope.pagination.rowsNumber.toLocaleString()
            : (
                scope.pagination.rowsPerPage * scope.pagination.page
              ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
        (scope.pagesNumber > 2)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              icon: "first_page",
              color: "grey-8",
              round: "",
              dense: "",
              flat: "",
              disable: scope.isFirstPage,
              onClick: scope.firstPage,
              class: "q-ml-md"
            }, null, 8, ["disable", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_q_btn, {
          icon: "chevron_left",
          color: "grey-8",
          round: "",
          dense: "",
          flat: "",
          disable: scope.isFirstPage,
          onClick: scope.prevPage
        }, null, 8, ["disable", "onClick"]),
        _createVNode(_component_q_btn, {
          icon: "chevron_right",
          color: "grey-8",
          round: "",
          dense: "",
          flat: "",
          disable: scope.isLastPage,
          onClick: scope.nextPage
        }, null, 8, ["disable", "onClick"]),
        (scope.pagesNumber > 2)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              icon: "last_page",
              color: "grey-8",
              round: "",
              dense: "",
              flat: "",
              disable: scope.isLastPage,
              onClick: scope.lastPage
            }, null, 8, ["disable", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _cache[3] || (_cache[3] = _createTextVNode(" Нет данных ")),
        _createVNode(_component_q_inner_loading, {
          showing: childLoader.value,
          color: "grey"
        }, null, 8, ["showing"])
      ])
    ]),
    _: 1
  }, 8, ["rows", "columns", "row-key", "pagination", "selected"]))
}
}

})