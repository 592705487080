import {
  computed,
  MaybeRefOrGetter,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  toRef,
  toValue,
} from "vue";

type FunctionWithAbort<T extends (...args: any) => Promise<any>> = (
  signal: AbortController,
  ...args: ParamsWithoutFirst<Parameters<T>>
) => Promise<any>;

type ParamsWithoutFirst<T extends unknown[]> = T extends [
  infer _,
  ...infer Rest
]
  ? Rest
  : never;

type AbortFn<T extends FunctionWithAbort<T>> = (
  ...args: ParamsWithoutFirst<Parameters<T>>
) => Promise<ReturnType<T> extends Promise<infer R> ? R : never>;
/**
 * @typedef {Object} useAbortRequestReturnType
 * @property {FunctionWithAbort<T>} fn - Function to wrap with abort controller
 * @property {AbortController} abortController - Abort controller to cancel the request if not needed
 */

/**
 * Creates a function that wraps a given function with abort controller.
 *
 * Return abortable function and an abort controller to cancel the request if not needed
 *
 * First argument should be defined as AbortController, in the result
 * function type it will be omitted
 *
 * The returned function will abort the previous request if it exists
 * and create a new abort controller for the new request.
 *
 * @example
 * const myVeryCustomFunction = (signal:AbortController,arg1:any,arg2:number,arg3:OtherType,arg4?:MaybeUndefined,...etc) => {
 *  return ApiCallWithAbortController(...your params,signal)
 * }
 * const {abortFn: AbortableApiCall,abortController} = useAbortRequest(myVeryCustomFunction)
 * //fetchWithAbort will have a type: (arg1:any,arg2:number,arg3:OtherType,arg4?:MaybeUndefined,...etc) => Promise<ApiCallWithAbortControllerResponse>
 *
 * Manual abort of a request
 * @example
 *
 * const myVeryCustomFunction = (signal:AbortController,arg1:any,arg2:number,arg3:OtherType,arg4?:MaybeUndefined,...etc) => {
 *  return ApiCallWithAbortController(...your params,signal)
 * }
 * const {abortFn: AbortableApiCall,abortController} = useAbortRequest(myVeryCustomFunction)
 *
 * //Shorter call by passing a link of a function, prefer using this one if you have only one thing to call in this hook
 * onUnmount(abortController.abort)
 *
 * // With anonymous function call, if you will have more logic in this hook then use anonymous fn
 * onUnmount(() => abortController.abort())
 *
 * @template T - The type of the function to wrap
 * @param {FunctionWithAbort<T>} fn - Function to wrap with abort controller
 * @returns {{ abortFn: AbortFn<T>, abortRequest: Ref<AbortController | undefined> }} - Wrapped function with abort controller and omitted abort controller argument type and optionally abortController
 */

export const useAbortRequest = <T extends FunctionWithAbort<T>>(
  fn: T
): { abortFn: AbortFn<T>; abortRequest: Ref<AbortController | undefined> } => {
  const abortRequest = ref<AbortController>();

  const abortFn: AbortFn<T> = (...args) => {
    if (abortRequest.value) {
      abortRequest.value.abort();
    }

    abortRequest.value = new AbortController();

    return fn(abortRequest.value, ...args);
  };

  const cancelRequestOnWindowReload = () => abortRequest.value?.abort();

  onMounted(() => {
    window.addEventListener("beforeunload", cancelRequestOnWindowReload);
  });

  onBeforeUnmount(() => {
    abortRequest.value?.abort();
    window.removeEventListener("beforeunload", cancelRequestOnWindowReload);
  });

  return {
    abortFn,
    abortRequest,
  };
};
