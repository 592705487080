// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_type.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessageTypeService } from "./message_type";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListMessageTypeResponse } from "./message_type";
import type { ListMessageTypeRequest } from "./message_type";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageTypeService
 */
export interface IMessageTypeServiceClient {
    /**
     * @generated from protobuf rpc: ListMessageType(proto.nigmamsg.ListMessageTypeRequest) returns (proto.nigmamsg.ListMessageTypeResponse);
     */
    listMessageType(input: ListMessageTypeRequest, options?: RpcOptions): UnaryCall<ListMessageTypeRequest, ListMessageTypeResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageTypeService
 */
export class MessageTypeServiceClient implements IMessageTypeServiceClient, ServiceInfo {
    typeName = MessageTypeService.typeName;
    methods = MessageTypeService.methods;
    options = MessageTypeService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListMessageType(proto.nigmamsg.ListMessageTypeRequest) returns (proto.nigmamsg.ListMessageTypeResponse);
     */
    listMessageType(input: ListMessageTypeRequest, options?: RpcOptions): UnaryCall<ListMessageTypeRequest, ListMessageTypeResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageTypeRequest, ListMessageTypeResponse>("unary", this._transport, method, opt, input);
    }
}
