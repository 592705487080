import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  style: {"height":"100%","box-shadow":"none"},
  class: "relative-position"
}
const _hoisted_2 = {
  key: 0,
  class: "q-pa-md col-12",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_4 = { class: "col-3 text-subtitle1 ellipsis-2-lines" }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_7 = {
  key: 1,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "row q-ml-sm" }
const _hoisted_10 = {
  class: "col-12",
  style: {"width":"100%","height":"calc(100% - 73px)","margin":"10px 0px 0px 10px"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { title: "Исходные ответы" }
const _hoisted_14 = { class: "row" }

import {computed, ref} from "vue";
import type {QTableColumn} from "quasar";
import {Filter} from "proto/nigma-common-proto/filter";
import {useAgQuestionApi} from "@/components/modules/polls/services/useAgQuestionApi";
import {AgQuestionModel} from "proto/nigma-message-proto/polls/ag_question";
import store from "@/store";
import {
  MessagePanelProps
} from "@/components/modules/polls/components/generalMessages/classification/MultipleClassificationMessages.vue";
import {useTextHighlighter} from "@/composables/useTextHighlighter";

type FindType = {
  id: number,
  title: string,
  value: string,
  disable?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionMessages',
  props: {
    currentNode: {},
    multipleClassifier: { type: Boolean },
    agPollId: {},
    agQuestionId: {},
    messageTypeIds: {}
  },
  emits: ['showSource', 'afterAction'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
const emit = __emit

const { listAgQuestionRequest, reduceAgQuestionRequest } = useAgQuestionApi()
const { markedFindValue } = useTextHighlighter()

const pseudoTableData = ref<AgQuestionModel[]>([])
const pseudoTableLoader = ref(false)

const isActiveModal = ref(false)
const currentQuestion = ref<AgQuestionModel | undefined>()
const columnsWorked = ref<QTableColumn[]>([
  {
    name: 'agQuestionStitle',
    field: 'agQuestionStitle',
    label: 'Наименование вопроса',
    align: 'left',
  },
  {
    name: 'answerSrcSum',
    field: 'answerSrcSum',
    label: 'Количество ответов',
    align: 'center',
  },
])
const similarColumn = ref<QTableColumn>({
  name: 'similarity',
  field: 'similarity',
  label: 'Коэффициент схожести',
  align: 'center',
})
const workedFilter = ref<Filter>({
  items: [
    {
      fieldName: 'aq.ag_question_id',
      operand: '<>',
      value: '0'
    }
  ]
})
const workedPagination = ref({
  sortBy: 'answer_grp_sum',
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
})
const rowsPerPageWorkedOptions = [10, 25, 50, 100]
const findType = ref<FindType>({
  id: 0,
  title: 'Поиск по семантике',
  value: 'similar',
})
const findTypeOptions = ref<FindType[]>([
  { id: 0, title: 'Поиск по семантике', value: 'similar' },
  { id: 1, title: 'Простой поиск', value: 'single' },
])
const markedFind = ref<string[]>([])
const selectedWorked = ref([])
const search = ref('')
const similarity = ref('0.6')

const userRole = computed(() => store.getters.getUserAccess)

// const onRowShowAnswerSource = (row: any, isFull: boolean) => emit('showSource', row, isFull)

// const hideModal = () => {
//   isActiveModal.value = false
//   currentQuestion.value = undefined
// }

const makeActionWorked = () => {
  let reducedIds = selectedWorked.value.map(item => item.agQuestionId)

  reduceAgQuestionRequest({
    agQuestionId: currentQuestion.value?.agQuestionId || 0,
    reducedIds: reducedIds
  })
      .then(() => emit('afterAction'))
}

const changeFindType = (event: any) => {
  if (event.value === 'single') {
    if (columnsWorked.value.length === 3) columnsWorked.value.splice(2, 1)
  }

  if (event.value === 'similar') {
    if (columnsWorked.value.length === 2) columnsWorked.value.splice(2, 0, similarColumn.value)
  }

  findAnswers()
}

const onRequestWorked = (props: unknown) => {
  workedPagination.value = props.pagination
  loadWorkedAnswers(false)
}

const findAnswers = () => {
  markedFind.value = []

  if (findType.value.value === 'single') {
    workedFilter.value.items[1] = {
      fieldName: 'aq.ag_question_stitle',
      operand: 'ilike',
      value: search.value,
    }

    if (search.value.length) markedFind.value.push(search.value)
  } else {
    let similarValue = parseFloat(similarity.value)

    workedFilter.value.items[1] = {
      fieldName: 'aq.ag_question_stitle',
      operand: 'similar_' + similarValue.toFixed(2),
      value: currentQuestion.value?.agQuestionStitle || '',
    }
  }

  loadWorkedAnswers(true)
}

const loadWorkedAnswers = (isResetPagination: boolean) => {
  pseudoTableLoader.value = true

  if (isResetPagination) {
    workedPagination.value.page = 1
    workedPagination.value.rowsNumber = 0
  }

  if (findType.value.value === 'single') {
    workedPagination.value.sortBy = 'answer_src_sum'
    workedPagination.value.descending = true
  } else {
    workedPagination.value.sortBy = 'similarity'
    workedPagination.value.descending = true
  }

  listAgQuestionRequest({
    filter: workedFilter.value,
    sort: {
      name: workedPagination.value.sortBy,
      exp: workedPagination.value.descending ? 'DESC' : 'ASC',
    },
    pagination: {
      page: workedPagination.value.page,
      limit: workedPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    },
    agPollId: props.agPollId,
    isWorked: true
  })
      .then(res => {
        workedPagination.value.rowsNumber = res.params.pagination.cnt
        pseudoTableData.value = res.items
      })
      .finally(() => pseudoTableLoader.value = false)
}

const loadData = (isLoadQuestions: boolean, isResetSimilarity: boolean, selectedRow: AgQuestionModel) => {
  findType.value = findTypeOptions.value[0]
  currentQuestion.value = selectedRow

  selectedWorked.value = []
  markedFind.value = []

  if (isResetSimilarity) {
    search.value = ''
    findType.value = {
      id: 0,
      title: 'Поиск по семантике',
      value: 'similar',
    }
    similarity.value = '0.6'
  }

  if (findType.value.value === 'similar') {
    findType.value = findTypeOptions.value[1]
  }

  if (findType.value.value === 'single') {
    if (columnsWorked.value.length === 3) {
      columnsWorked.value.splice(2, 1)
    }
  } else {
    if (columnsWorked.value.length === 2) {
      columnsWorked.value.splice(2, 0, similarColumn.value)
    }
  }

  if (isLoadQuestions) {
    let extFilter
    workedFilter.value.items[0].value = currentQuestion.value?.agQuestionId.toString() || '0'

    if (findType.value.value === 'single') {
      extFilter = {
        fieldName: 'aq.ag_question_stitle',
        operand: 'ilike',
        value: search.value,
      }

      if (search.value.length) {
        markedFind.value.push(search.value)
      }
    } else {
      extFilter = {
        fieldName: 'aq.ag_question_stitle',
        operand: 'similar_' + parseFloat(similarity.value).toFixed(2),
        value: currentQuestion.value?.agQuestionStitle,
      }
    }

    workedFilter.value.items[1] = extFilter
    loadWorkedAnswers(true)
  }
}

__expose({loadData})

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (currentQuestion.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[7] || (_cache[7] = _createTextVNode(" Выбранный вопрос: ")),
              _createElementVNode("span", {
                class: "text-weight-bold",
                title: currentQuestion.value.agQuestionStitle
              }, "(" + _toDisplayString(currentQuestion.value.answerSrcSum.toLocaleString()) + ") " + _toDisplayString(currentQuestion.value.agQuestionStitle), 9, _hoisted_5)
            ]),
            (findType.value.value === 'similar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    type: "number",
                    modelValue: similarity.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((similarity).value = $event)),
                    filled: "",
                    min: "0.01",
                    max: "0.99",
                    step: "0.01",
                    label: "Коэффициент схожести",
                    onKeydown: _withKeys(_withModifiers(findAnswers, ["prevent"]), ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"])
                ]))
              : _createCommentVNode("", true),
            (findType.value.value === 'single')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_q_input, {
                    filled: "",
                    modelValue: search.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                    label: "Текст для поиска",
                    onKeydown: _withKeys(_withModifiers(findAnswers, ["prevent"]), ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_btn, {
              style: {"padding":"10px","margin-right":"10px"},
              "background-color": "gray-12",
              icon: "mdi-magnify",
              onClick: findAnswers
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Найти вопросы")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_select, {
                options: findTypeOptions.value,
                modelValue: findType.value,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((findType).value = $event)),
                  _cache[3] || (_cache[3] = ($event: any) => (changeFindType(findType.value)))
                ],
                filled: "",
                "option-value": "value",
                "option-label": "title",
                label: "Тип поиска"
              }, null, 8, ["options", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              ('admin editor'.includes(userRole.value))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    color: "primary",
                    label: "Объединить",
                    onClick: makeActionWorked,
                    style: {"height":"56px"},
                    disable: !selectedWorked.value.length
                  }, null, 8, ["disable"]))
                : _createCommentVNode("", true),
              (!selectedWorked.value.length)
                ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "text-subtitle2" }, "Выберите хотя бы один вопрос", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_q_table, {
              style: _normalizeStyle([{"overflow":"auto"}, pseudoTableData.value.length ? 'height: 41vh' : null]),
              class: "sticky-header-table-answer",
              flat: "",
              dense: "",
              rows: pseudoTableData.value,
              columns: columnsWorked.value,
              "row-key": "agQuestionId",
              selection: 'admin editor'.includes(userRole.value) ? 'multiple' : 'none',
              "wrap-cells": "",
              selected: selectedWorked.value,
              "onUpdate:selected": _cache[4] || (_cache[4] = ($event: any) => ((selectedWorked).value = $event)),
              pagination: workedPagination.value,
              "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((workedPagination).value = $event)),
              "rows-per-page-options": rowsPerPageWorkedOptions,
              onRequest: onRequestWorked
            }, {
              "body-cell-agQuestionStitle": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (markedFind.value.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _unref(markedFindValue)(props.value, [], search.value)
                        }, null, 8, _hoisted_11))
                      : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-answerSrcSum": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  style: {"width":"60px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_13, _toDisplayString(props.value.toLocaleString()), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-similarity": _withCtx((props) => [
                _withDirectives(_createVNode(_component_q_td, {
                  props: props,
                  style: {"width":"80px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value.toFixed(4)), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]), [
                  [_vShow, findType.value.value = 'similar']
                ])
              ]),
              "no-data": _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["style", "rows", "columns", "selection", "selected", "pagination"])
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Не выбран вопрос в левой панели")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, !currentQuestion.value]
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_q_inner_loading, {
      showing: _ctx.isQuestionForSearchBusy,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})