import { useGrpcClientWithLogger } from "@/components/modules/rec-system/services/UserGroups/middleware";
import { AgPollServiceClient } from "proto/nigma-message-proto/polls/ag_poll.client";
import { ListAgPollRequest } from "proto/nigma-message-proto/polls/ag_poll";

export const useAgPollApi = () => {
  const client = useGrpcClientWithLogger().create(AgPollServiceClient, {
    baseUrl: window.VUE_APP_GATEWAY_URL,
  });

  const metadata = () => {
    return {
      Authorization: localStorage.getItem("access-key") || "",
    };
  };

  return {
    listAgPollRequest: (request: ListAgPollRequest) => {
      return client.listAgPoll(request, { meta: metadata() }).response;
    },
  };
};
