import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {MessageTypeServiceClient} from "proto/nigma-message-proto/polls/message_type.client";
import {
    ListMessageTypeRequest,
} from "proto/nigma-message-proto/polls/message_type"

export const useMessageTypeApi = () => {
    const client = useGrpcClientWithLogger().create(MessageTypeServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listMessageTypeRequest: (request: ListMessageTypeRequest) => {
            return client.listMessageType(request, {meta: metadata()}).response
        },
    }
}