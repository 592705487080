import { useGrpcClientWithLogger } from "@/components/modules/rec-system/services/UserGroups/middleware";
import { AgAnswerGrpServiceClient } from "proto/nigma-message-proto/polls/ag_answer_grp.client";
import {
  ListAgAnswerGrpRequest,
  ListAgAnswerGrpWithFindAnswerRequest,
  ListAgAnswerGrpChildsRequest,
  ReduceAgAnswerGrpRequest,
  SeparateAgAnswerGrpRequest,
} from "proto/nigma-message-proto/polls/ag_answer_grp";

export const useAgAnswerGrpApi = () => {
  const client = useGrpcClientWithLogger().create(AgAnswerGrpServiceClient, {
    baseUrl: window.VUE_APP_GATEWAY_URL,
  });

  const metadata = () => {
    return {
      Authorization: localStorage.getItem("access-key") || "",
    };
  };

  return {
    listAgAnswerGrpRequest: (
      request: ListAgAnswerGrpRequest,
      abortController?: AbortController
    ) => {
      return client
        .listAgAnswerGrp(request, { abort: abortController?.signal })
        .response.catch(console.error);
    },
    listAgAnswerGrpWithFindAnswerRequest: (
      request: ListAgAnswerGrpWithFindAnswerRequest,
      abortController?: AbortController
    ) => {
      return client
        .listAgAnswerGrpWithFindAnswer(request, {
          abort: abortController?.signal,
        })
        .response.catch(console.error);
    },
    listAgAnswerGrpChildsRequest: (request: ListAgAnswerGrpChildsRequest) => {
      return client.listAgAnswerGrpChilds(request, { meta: metadata() })
        .response;
    },
    reduceAgAnswerGrpRequest: (request: ReduceAgAnswerGrpRequest) => {
      return client.reduceAgAnswerGrp(request, { meta: metadata() }).response;
    },
    separateAgAnswerGrpRequest: (request: SeparateAgAnswerGrpRequest) => {
      return client.separateAgAnswerGrp(request, { meta: metadata() }).response;
    },
  };
};
