import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px","max-height":"87vh"} }
const _hoisted_2 = {
  class: "row items-stretch",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = { class: "col-12 q-pr-lg" }
const _hoisted_5 = { class: "col-12 q-pr-lg q-mt-lg" }
const _hoisted_6 = {
  class: "col-12 bg-white text-black shadow-4 relative-position",
  style: {"height":"74vh","overflow":"hidden"}
}
const _hoisted_7 = {
  class: "row cls-answers__actions items-center",
  style: {"min-height":"40px"}
}
const _hoisted_8 = { class: "col-lg-7 col-xl-9 cls-answers__input q-pa-sm" }
const _hoisted_9 = {
  key: 0,
  class: "row col-xl-2 col-lg-3 justify-end"
}
const _hoisted_10 = {
  class: "row q-pl-sm q-pr-sm",
  style: {"width":"100%"}
}
const _hoisted_11 = {
  key: 0,
  class: "tree-input-dropdown shadow-4",
  style: {"background":"white","max-height":"500px","overflow":"auto","position":"absolute","z-index":"4","width":"100%"}
}
const _hoisted_12 = {
  style: {"width":"100%","height":"55vh","overflow":"auto"},
  class: "custom-tree q-pa-sm"
}
const _hoisted_13 = { class: "row items-center" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  class: "col-7 shadow-4 bg-white text-black",
  style: {"overflow":"hidden"}
}
const _hoisted_17 = {
  key: 0,
  class: "text-h6 q-pa-sm bg-white text-black"
}
const _hoisted_18 = { class: "text-weight-bold" }
const _hoisted_19 = {
  key: 1,
  class: "text-h6 q-pa-sm bg-white text-black"
}

import store from "@/store";
import { computed, onMounted, ref } from "vue";
import ClassAdd from "../../../components/class/ClassAdd.vue";
import ClassEdit from "../../../components/class/ClassEdit.vue";
import ClassDelete from "../../../components/class/ClassDelete.vue";
import { useClassApi } from "@/components/modules/polls/services/useClassApi";
import { useClassifierApi } from "@/components/modules/polls/services/useClassifierApi";
import { ClassifierModel } from "proto/nigma-message-proto/polls/classifier";
import { TreeNode, useTreeConverter } from "@/composables/useTreeConverter";
import { ClassModel } from "proto/nigma-message-proto/polls/class";
import { useClassTagApi } from "@/components/modules/polls/services/useClassTagApi";
import { ClassTagModel } from "proto/nigma-message-proto/polls/class_tag";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import type { QSelect, QTree } from "quasar";
import MultipleClassificationMessages from "@/components/modules/polls/components/generalMessages/classification/MultipleClassificationMessages.vue";
import SourceTable from "@/components/modules/polls/components/SourceTable.vue";
import { ClassNotTagModel } from "proto/nigma-message-proto/polls/class_not_tag";
import { useClassNotTagApi } from "@/components/modules/polls/services/useClassNotTagApi";
import { onClickOutside } from "@vueuse/core";
import { MessageTypeModel } from "@/proto/nigma-message-proto/polls/message_type";
import { useMessageTypeApi } from "../../../services/useMessageTypeApi";

//TODO CRUD DISABLES

export interface ClassCrudProps {
  clsId?: number;
  currentNode?: TreeNode<ClassModel> | undefined;
  currTab?: string;
  currentTags?: ClassTagModel[] | undefined;
  currentNotTags?: ClassNotTagModel[] | undefined;
}

export interface ClassTagsCrudProps {
  tagItem?: ClassTagModel & { index: number };
}

export interface ClassNotTagsCrudProps {
  notTagItem?: ClassNotTagModel & { index: number };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleClassification',
  setup(__props) {

const { listClassRequest } = useClassApi();
const { listClassifierRequest } = useClassifierApi();
const { listClassTagRequest } = useClassTagApi();
const { listClassNotTag } = useClassNotTagApi();
const { treeConverter, getNodeByKey } = useTreeConverter();
const { markedFindValue } = useTextHighlighter();
const { listMessageTypeRequest } = useMessageTypeApi();

const convertedClassItems = ref<TreeNode<ClassModel>[]>([]);
const classifierOptions = ref<ClassifierModel[]>([]);
const classifierSearchOptions = ref<ClassifierModel[]>([]);
const classifier = ref<ClassifierModel | undefined>();
const classTreeLoader = ref(false);
const clsMessagePanel =
  ref<InstanceType<typeof MultipleClassificationMessages>>();
const classTags = ref<ClassTagModel[]>([]);
const classNotTags = ref<ClassNotTagModel[]>([]);
const currentNode = ref<TreeNode<ClassModel> | undefined>();

const isActiveModal = ref(false);
const isActiveModalSource = ref(false);
const isActiveModalAdd = ref(false);
const isActiveModalEdit = ref(false);
const isActiveModalDelete = ref(false);
const currentCountMessage = ref(null);
const isFullCount = ref(false);
const filter = ref("");
const search = ref("");
const oldSearch = ref("");
const searchTimeout = ref<ReturnType<typeof setTimeout>>(
  setTimeout(() => {}, 500)
);
const minSearchSymbol = ref(3);
const selectedNode = ref("");
const currentTab = ref("");
const classifierSelect = ref<InstanceType<typeof QSelect>>();
const messageTypeRef = ref<InstanceType<typeof HTMLDivElement>>();
const isDropdownOpen = ref(false);
const filterInput = ref("");
const projectProfileTree = ref<InstanceType<typeof QTree>>();
const messageTypes = ref<MessageTypeModel[]>([]);
const messageTypeIds = ref<number[]>([]);
const projectAssociation = ref({});

const userRole = computed(() => store.getters.getUserAccess);

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalSource.value = false;
  isActiveModalAdd.value = false;
  isActiveModalEdit.value = false;
  isActiveModalDelete.value = false;
};

const onRowShowMessageSource = (row: any, isFull: boolean) => {
  hideModal();
  currentCountMessage.value = Object.assign(row);
  isFullCount.value = isFull;
  isActiveModalSource.value = true;
  isActiveModal.value = true;
};

const addClass = () => {
  hideModal();
  isActiveModalAdd.value = true;
  isActiveModal.value = true;
};

const editClass = (tabType: string) => {
  if (tabType === "main") currentTab.value = "main";
  else if (tabType === "tags") currentTab.value = "tags";
  else if (tabType === "notTags") currentTab.value = "not_tags";

  hideModal();
  isActiveModalEdit.value = true;
  isActiveModal.value = true;
};

const deleteClass = () => {
  hideModal();
  isActiveModalDelete.value = true;
  isActiveModal.value = true;
};

const afterAction = (setNode: string, type?: string) => {
  loadClassTreeItems(setNode);
  hideModal();

  if (type === "delete") currentNode.value = undefined;
};

const popupHideClassifier = () => {
  const modelValue: any = JSON.parse(
    JSON.stringify(classifierSelect.value?.modelValue)
  );
  if (classifier.value?.classifierId === modelValue.id) {
    changeListClassifier(modelValue);
  }
};

const filterClassifier = (val: string, update: Function) => {
  update(() => {
    if (val === "") {
      classifierSearchOptions.value = classifierOptions.value;
    } else {
      const needle = val.toLowerCase();

      classifierSearchOptions.value = classifierOptions.value.filter(
        (v) => v.classifierName.toLowerCase().indexOf(needle) > -1
      );
    }
  });
};

const changeListClassifier = (event: ClassifierModel) => {
  if (!event) {
    if (classifierSelect.value && classifier.value) {
      classifierSelect.value?.updateInputValue(
        classifier.value?.classifierName || ""
      );
    }
  } else {
    const name: string = event.classifierName;

    if (
      name.indexOf("<span style='color: red;'>") > -1 &&
      name.indexOf("</b></span>") > -1 &&
      classifierOptions.value
    ) {
      classifier.value = classifierOptions.value.find(
        (opt) => event.classifierId === opt.classifierId
      );
    } else {
      classifier.value = {
        classifierId: event.classifierId,
        classifierName: event.classifierName,
        classifierDescr: event.classifierDescr,
        classifierNotes: event.classifierNotes,
        classifierMultiple: event.classifierMultiple,
        classessCnt: event.classessCnt,
      };
    }

    currentNode.value = undefined;

    initSearch();
    loadClassTreeItems();
  }
};

const selectNode = () => {
  if (selectedNode.value) {
    currentNode.value = getNodeByKey(
      "classId",
      Number(selectedNode.value),
      convertedClassItems.value
    );
  } else {
    currentNode.value = undefined;
  }

  if (currentNode.value) {
    Promise.all([
      listClassTagRequest({
        filter: {
          items: [
            {
              fieldName: "class_id",
              operand: "=",
              value: currentNode.value?.classId.toString() || "0",
            },
          ],
        },
        sort: {
          name: "",
          exp: "",
        },
        pagination: {
          page: 1,
          pages: 0,
          limit: 100000,
          cnt: 0,
        },
      }),
      listClassNotTag({
        filter: {
          items: [
            {
              fieldName: "class_id",
              operand: "=",
              value: currentNode.value?.classId.toString() || "0",
            },
          ],
        },
        sort: {
          name: "",
          exp: "",
        },
        pagination: {
          page: 1,
          pages: 0,
          limit: 100000,
          cnt: 0,
        },
      }),
    ]).then(([tagsRes, notTagsRes]) => {
      classTags.value = tagsRes.items;
      classNotTags.value = notTagsRes.items;

      if (clsMessagePanel.value)
        clsMessagePanel.value?.loadData(
          true,
          classTags.value,
          classNotTags.value
        );
    });
  }
};

const setTreeNode = (setNode: string) => {
  selectedNode.value = setNode;
  selectNode();

  currentNode.value = getNodeByKey(
    "classId",
    Number(selectedNode.value),
    convertedClassItems.value
  );

  //TODO REASON???

  // while (currentNode.value && currentNode.value?.classParentId !== undefined) {
  //   currentNode.value = getNodeByKey('classParentId', Number(currentNode.value?.classParentId), convertedClassItems.value)
  //
  //   if (currentNode.value) {
  //     tree.setExpanded(curNode.classId, true)
  //   }
  // }
};

const initSearch = () => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }

  filter.value = "";
  search.value = "";
  oldSearch.value = "";
  searchTimeout.value = setTimeout(() => {}, 500);
};

const onInputSearchControl = (event: KeyboardEvent) => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }

  searchTimeout.value = setTimeout(() => {
    const searchStr: string = event.target.value;

    if (searchStr.length >= minSearchSymbol.value) {
      search.value = searchStr.toLowerCase();
      //this.loadClassTreeItems(null) // Временно убран серверный поиск !!!
    } else {
      clearSearch();
    }
    oldSearch.value = searchStr;
  }, 500);
};

const clearSearch = () => (search.value = "");

const loadClassTreeItems = (setNode?: string) => {
  classTreeLoader.value = true;

  listClassRequest({
    filter: {
      items: [
        {
          fieldName: "classifier_id",
          operand: "=",
          value: classifier.value?.classifierId.toString() || "0",
        },
      ],
    },
    sort: {
      name: "",
      exp: "",
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 10000,
      cnt: 0,
    },
  })
    .then((res) => {
      convertedClassItems.value = treeConverter(res.items, {
        id: "classId",
        parentId: "classParentId",
      });
      if (setNode) setTreeNode(setNode);
    })
    .finally(() => (classTreeLoader.value = false));
};

const loadClassifierItems = () => {
  listClassifierRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "",
      exp: "",
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 10000,
      cnt: 0,
    },
  }).then((res) => {
    classifierOptions.value = res.items;
    classifierSearchOptions.value = classifierOptions.value;
    changeListClassifier(classifierOptions.value[0]);
  });
};

const loadMessageTypes = () => {
  listMessageTypeRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "message_type_text",
      exp: "asc",
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    },
  }).then((res) => {
    const treeNodes: any = [
      {
        id: "alltypes",
        label: "Все типы сообщений",
        children: [],
      },
    ];

    const tempObj: any = {};

    for (const i of res.items) {
      if (!tempObj[i.projectId]) {
        tempObj[i.projectId] = {
          label:
            'Все типы сообщений по проекту "' +
            projectAssociation.value[i.projectId] +
            '"',
          id: i.messageTypeText + "#" + i.projectId,
          children: [],
        };

        tempObj[i.projectId].children.push({
          label: i.messageTypeText,
          id: i.messageTypeId,
        });
      } else {
        tempObj[i.projectId].children.push({
          label: i.messageTypeText,
          id: i.messageTypeId,
        });
      }
    }

    for (const i in tempObj) {
      treeNodes[0].children.push(tempObj[i]);
    }

    messageTypes.value = treeNodes;
  });
};

const loadProjects = () => {
  store
    .dispatch("getProjectsItems", {
      filter: [
        {
          field_name: "is_visible",
          operand: "=",
          value: "true",
        },
      ],
      sort: {
        name: "project_id",
        exp: "asc",
      },
    })
    .then((res) => {
      const filteredAssocType = [];

      for (const i of res.items) {
        filteredAssocType.push([i.getProjectId(), i.getProjectName()]);
      }

      projectAssociation.value = Object.fromEntries(filteredAssocType);
    })
    .then(() => {
      loadMessageTypes();
    });
};

const showDropDown = (value: boolean) => {
  isDropdownOpen.value = value;
  projectProfileTree.value?.expandAll();
};

onClickOutside(messageTypeRef, (e: any) => {
  e.target.closest(".project-select-tree") ? null : showDropDown(false);
});

const loadData = () => {
  hideModal();
  loadProjects();
  loadClassifierItems();
};

onMounted(() => loadData());

return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, "Классификация", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_select, {
            ref_key: "classifierSelect",
            ref: classifierSelect,
            filled: "",
            modelValue: classifier.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((classifier).value = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (changeListClassifier($event)))
            ],
            "use-input": "",
            "hide-selected": "",
            "fill-input": "",
            "input-debounce": "300",
            options: classifierSearchOptions.value,
            "option-value": "classifierId",
            "option-label": "classifierName",
            "options-dense": "",
            "options-html": "",
            label: "Классификаторы",
            onFilter: filterClassifier,
            onPopupHide: popupHideClassifier
          }, {
            "no-option": _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { class: "text-grey" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Не найдено ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            option: _withCtx((scope) => [
              _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            size: "20px",
                            name: 
                        scope.opt.classifierMultiple
                          ? 'mdi-email-multiple-outline'
                          : 'mdi-email-outline'
                      
                          }, null, 8, ["name"]),
                          _createTextVNode(" " + _toDisplayString(scope.opt.classifierName), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, "Классы", -1)),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_q_input, {
                  style: {"margin":"1px 1px"},
                  disable: classTreeLoader.value,
                  modelValue: filter.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter).value = $event)),
                  filled: "",
                  onKeyup: _cache[3] || (_cache[3] = ($event: any) => (onInputSearchControl($event))),
                  label: "Поиск по классам"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "mdi-magnify" })
                  ]),
                  _: 1
                }, 8, ["disable", "modelValue"])
              ]),
              ('admin editor'.includes(userRole.value))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_q_btn, {
                      label: "ДЕЙСТВИЯ",
                      color: "primary",
                      style: {"height":"56px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  onClick: addClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-plus" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                                        _createTextVNode("Добавить класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: !selectedNode.value,
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (editClass('main')))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-pencil" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("Редактировать класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable"])), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: 
                          currentNode.value?.children.length !== 0 || !selectedNode.value
                        ,
                                  onClick: deleteClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-close" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode("Удалить класс")
                                      ])),
                                      _: 1
                                    }),
                                    (currentNode.value?.children.length)
                                      ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                                            _createElementVNode("div", { class: "text-subtitle2" }, " Нельзя удалить класс, имеющий дочерние элементы ", -1)
                                          ])),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["disable"])), [
                                  [_directive_close_popup]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", {
                ref_key: "messageTypeRef",
                ref: messageTypeRef,
                class: "tree-input col",
                style: {"position":"relative"}
              }, [
                _createVNode(_component_q_input, {
                  modelValue: filterInput.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filterInput).value = $event)),
                  filled: "",
                  label: 
                    messageTypeIds.value.length
                      ? 'Выбрано типов сообщений: (' +
                        messageTypeIds.value.length +
                        ')'
                      : 'Все типы сообщений'
                  ,
                  clearable: "",
                  onFocus: _cache[6] || (_cache[6] = ($event: any) => (showDropDown(true))),
                  style: {"width":"100%"}
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: 
                        isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
                      
                    }, null, 8, ["name"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "label"]),
                (isDropdownOpen.value && messageTypes.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_q_tree, {
                        ticked: messageTypeIds.value,
                        "onUpdate:ticked": _cache[7] || (_cache[7] = ($event: any) => ((messageTypeIds).value = $event)),
                        nodes: messageTypes.value,
                        "node-key": "id",
                        "label-key": "label",
                        "children-key": "children",
                        "tick-strategy": "leaf",
                        "default-expand-all": "",
                        filter: filterInput.value,
                        class: "shadow-4"
                      }, null, 8, ["ticked", "nodes", "filter"])
                    ]))
                  : _createCommentVNode("", true)
              ], 512),
              _createVNode(_component_q_btn, {
                label: "Применить",
                class: "q-ml-sm",
                color: "primary",
                onClick: selectNode
              })
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_q_tree, {
                nodes: convertedClassItems.value,
                "node-key": "classId",
                "label-key": "className",
                filter: search.value,
                selected: selectedNode.value,
                "onUpdate:selected": [
                  _cache[8] || (_cache[8] = ($event: any) => ((selectedNode).value = $event)),
                  selectNode
                ],
                "selected-color": "primary"
              }, {
                "default-header": _withCtx((prop) => [
                  _createElementVNode("div", _hoisted_13, [
                    (search.value.length > 0)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _unref(markedFindValue)(prop.node.className, [], search.value)
                        }, null, 8, _hoisted_14))
                      : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(prop.node.className), 1))
                  ])
                ]),
                _: 1
              }, 8, ["nodes", "filter", "selected"])
            ]),
            _createVNode(_component_q_inner_loading, {
              showing: classTreeLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", null, [
          (classifier.value?.classifierName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[16] || (_cache[16] = _createTextVNode(" Классификация по ")),
                _createElementVNode("span", _hoisted_18, "\"" + _toDisplayString(classifier.value?.classifierName) + "\"", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, " Классификация ")),
          _createVNode(_component_q_separator),
          _createVNode(MultipleClassificationMessages, {
            ref_key: "clsMessagePanel",
            ref: clsMessagePanel,
            onShowSource: onRowShowMessageSource,
            onEditClass: editClass,
            "current-node": currentNode.value,
            "multiple-classifier": classifier.value?.classifierMultiple || false,
            "message-type-ids": messageTypeIds.value
          }, null, 8, ["current-node", "multiple-classifier", "message-type-ids"])
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalSource.value)
          ? (_openBlock(), _createBlock(SourceTable, {
              key: 0,
              message: currentCountMessage.value,
              isFull: isFullCount.value,
              onHideModal: hideModal,
              type: "all"
            }, null, 8, ["message", "isFull"]))
          : _createCommentVNode("", true),
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(ClassAdd, {
              key: 1,
              "cls-id": classifier.value?.classifierId,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "current-node": currentNode.value
            }, null, 8, ["cls-id", "current-node"]))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(ClassEdit, {
              key: 2,
              "cls-id": classifier.value?.classifierId,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "curr-tab": currentTab.value,
              "current-node": currentNode.value,
              "current-tags": classTags.value,
              "current-not-tags": classNotTags.value
            }, null, 8, ["cls-id", "curr-tab", "current-node", "current-tags", "current-not-tags"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(ClassDelete, {
              key: 3,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "current-node": currentNode.value
            }, null, 8, ["current-node"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})