// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_type.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров mt.
 *
 * @generated from protobuf message proto.nigmamsg.MessageTypeModel
 */
export interface MessageTypeModel {
    /**
     * @generated from protobuf field: int64 message_type_id = 1;
     */
    messageTypeId: number;
    /**
     * @generated from protobuf field: int64 project_id = 2;
     */
    projectId: number;
    /**
     * @generated from protobuf field: string message_type_text = 3;
     */
    messageTypeText: string;
    /**
     * @generated from protobuf field: string project_name = 4;
     */
    projectName: string; // Префикс для фильтров p.project_name
    /**
     * @generated from protobuf field: string project_short_name = 5;
     */
    projectShortName: string; // Префикс для фильтров p.project_short_name
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageTypeRequest
 */
export interface ListMessageTypeRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageTypeResponse
 */
export interface ListMessageTypeResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageTypeModel items = 1;
     */
    items: MessageTypeModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageTypeRequest params = 2;
     */
    params?: ListMessageTypeRequest;
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageTypeModel$Type extends MessageType<MessageTypeModel> {
    constructor() {
        super("proto.nigmamsg.MessageTypeModel", [
            { no: 1, name: "message_type_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "message_type_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "project_short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageTypeModel>): MessageTypeModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageTypeId = 0;
        message.projectId = 0;
        message.messageTypeText = "";
        message.projectName = "";
        message.projectShortName = "";
        if (value !== undefined)
            reflectionMergePartial<MessageTypeModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageTypeModel): MessageTypeModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_type_id */ 1:
                    message.messageTypeId = reader.int64().toNumber();
                    break;
                case /* int64 project_id */ 2:
                    message.projectId = reader.int64().toNumber();
                    break;
                case /* string message_type_text */ 3:
                    message.messageTypeText = reader.string();
                    break;
                case /* string project_name */ 4:
                    message.projectName = reader.string();
                    break;
                case /* string project_short_name */ 5:
                    message.projectShortName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageTypeModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_type_id = 1; */
        if (message.messageTypeId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageTypeId);
        /* int64 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int64(message.projectId);
        /* string message_type_text = 3; */
        if (message.messageTypeText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.messageTypeText);
        /* string project_name = 4; */
        if (message.projectName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectName);
        /* string project_short_name = 5; */
        if (message.projectShortName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.projectShortName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageTypeModel
 */
export const MessageTypeModel = new MessageTypeModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageTypeRequest$Type extends MessageType<ListMessageTypeRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageTypeRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListMessageTypeRequest>): ListMessageTypeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListMessageTypeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageTypeRequest): ListMessageTypeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageTypeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageTypeRequest
 */
export const ListMessageTypeRequest = new ListMessageTypeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageTypeResponse$Type extends MessageType<ListMessageTypeResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageTypeResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageTypeModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageTypeRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageTypeResponse>): ListMessageTypeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageTypeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageTypeResponse): ListMessageTypeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageTypeModel items */ 1:
                    message.items.push(MessageTypeModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageTypeRequest params */ 2:
                    message.params = ListMessageTypeRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageTypeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageTypeModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageTypeModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageTypeRequest params = 2; */
        if (message.params)
            ListMessageTypeRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageTypeResponse
 */
export const ListMessageTypeResponse = new ListMessageTypeResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.MessageTypeService
 */
export const MessageTypeService = new ServiceType("proto.nigmamsg.MessageTypeService", [
    { name: "ListMessageType", options: {}, I: ListMessageTypeRequest, O: ListMessageTypeResponse }
]);
